// src/pages/employee-wellness.js

import React from "react"
// import BookADemo from "../components/BookADemo"
import { ApiDocumentation } from "../components/ApiDocumentation"

const Documentation = () => {
  return (
    <>
      <ApiDocumentation />
      {/* <BookADemo /> */}
    </>
  )
}

export default Documentation
