import React from "react"
import { CRMApiDocumentation } from "../components/CRMDocumentation"

const CRMDocumentation = () => {
  return (
    <>
      <CRMApiDocumentation />
    </>
  )
}

export default CRMDocumentation
